<template>
  <Feature :feature-slug="featureNames.TEXT_AI_TOPICS">
    <v-card
      class="mb-4"
      outlined
    >
      <v-card-title>
        <h3 class="overline grey--text text--darken-1">
          KI-Texte erstellen
          <v-badge
            content="BETA"
            color="orange"
            class="ml-2"
          />
        </h3>
      </v-card-title>
      <v-card-subtitle>
        Machen Sie es sich einfach: Lassen Sie sich Ihre Texte mit Hilfe von Künstlicher Intelligenz (KI) erstellen.
        Wählen Sie die Textart und los geht's.
      </v-card-subtitle>

      <v-card-text class="px-4">
        <template v-if="isAnyTextKindConfigured()">
          <div class="d-flex flex-wrap mx-n2">
            <div
              v-for="textKind in textKinds"
              :key="textKind.key"
              class="ma-2"
            >
              <GenerateDialog
                v-if="isTextKindEnabled(textKind.key)"
                :is-configured="isTextKindConfigured(textKind.key)"
                :title="textKind.title"
                :button-label="textKind.label"
                :button-icon="textKind.icon"
                :text-kind="textKind.key"
                :slug-type="slugType"
                :slug="slug"
              />
            </div>
          </div>

          <v-alert
            type="info"
            icon="mdi-lightbulb-on-20"
            class="mt-4 body-2"
            text
          >
            Wir stellen Ihnen hier eine erste <strong>BETA-Version</strong> unseres KI-Generators zur Verfügung.
            Daher freuen wir uns über jedes Feedback:<br>
            Sind Sie zufrieden mit den generierten Texten? Welche Textarten fehlen Ihnen?
            Was können wir allgemein an der Funktionalität verbessern?<br>
            <v-btn
              color="secondary"
              class="mt-4"
              href="https://survey.zohopublic.eu/zs/g7DHVn"
              target="_blank"
              rel="noopener noreferrer"
              small
              outlined
            >
              Jetzt Feedback geben …
            </v-btn>
          </v-alert>
        </template>

        <v-alert
          v-else
          type="info"
          icon="mdi-lightbulb-on-20"
          text
        >
          <strong>Diese {{ readableSlugType }} befindet sich aktuell noch im KI-Trainings-Camp.</strong><br>
          Schon bald sollte sie einsatzbereit sein. Schauen Sie in einigen Tagen wieder vorbei.
        </v-alert>
      </v-card-text>
    </v-card>
  </Feature>
</template>

<script>
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import url from '@/mixins/url'
import GenerateDialog from './GenerateDialog.vue'
import GET_AVAILABLE_TEXT_KINDS_FOR_TOPIC from './queries/getAvailableTextKindsForTopic.gql'

const textKinds = [{
  key: 'facebookPost',
  title: 'Facebook Post erstellen',
  label: 'Facebook Post',
  icon: 'mdi-facebook'
}, {
  key: 'instagramPost',
  title: 'Instagram Post erstellen',
  label: 'Instagram Post',
  icon: 'mdi-instagram'
}, {
  key: 'linkedinPost',
  title: 'LinkedIn Post erstellen',
  label: 'LinkedIn Post',
  icon: 'mdi-linkedin'
}, {
  key: 'facebookAds',
  title: 'Facebook Ads Anzeige erstellen',
  label: 'Facebook Ads',
  icon: 'mdi-facebook'
}, {
  key: 'blogPost',
  title: 'Blog Beitrag erstellen',
  label: 'Blog Beitrag',
  icon: 'mdi-text'
}, {
  key: 'gmbNewsPost',
  title: 'Google Business Profil – Newspost erstellen',
  label: 'Google Business Profil Newspost',
  icon: 'mdi-google-my-business'
}, {
  key: 'gmbProductDescription',
  title: 'Google Business Profil – Produktbeschreibung erstellen',
  label: 'Google Business Profil Produktbeschreibung',
  icon: 'mdi-google-my-business'
}, {
  key: 'customerMailing',
  title: 'Kundenanschreiben erstellen',
  label: 'Kundenanschreiben',
  icon: 'mdi-mail'
}, {
  key: 'websiteText',
  title: 'Text für Website erstellen',
  label: 'Text für Website',
  icon: 'mdi-web'
}]

export default {
  components: {
    Feature,
    GenerateDialog
  },

  mixins: [url],

  props: {
    slugType: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      featureNames,
      textKinds,
      availableTextKinds: []
    }
  },

  apollo: {
    availableTextKinds: {
      query: GET_AVAILABLE_TEXT_KINDS_FOR_TOPIC,
      variables () {
        return {
          input: {
            slugType: this.slugType,
            slug: this.slug
          }
        }
      }
    }
  },

  computed: {
    readableSlugType () {
      return ({
        LANDINGPAGE: 'Landingpage',
        CAMPAIGN: 'Kampagne'
      })[this.slugType]
    },

    availableTextKindsForCompany () {
      const { enabledTextKinds } = this.feature.config

      if (enabledTextKinds === 'all') {
        return this.availableTextKinds
      }

      return this.availableTextKinds
        .filter(availableTextKind => (
          enabledTextKinds.includes(availableTextKind)
        ))
    }
  },

  created () {
    this.feature = this.$features.feature(featureNames.TEXT_AI_TOPICS)
  },

  methods: {
    /**
     * Whether a text kind is enabled in the feature.
     */
    isTextKindEnabled (textKind) {
      const { enabledTextKinds } = this.feature.config
      return enabledTextKinds === 'all' || enabledTextKinds.includes(textKind)
    },

    /**
     * Whether a text kind (that is enabled in the feature)
     * is actually configured with prompts in the database.
     */
    isTextKindConfigured (textKind) {
      return this.availableTextKindsForCompany.includes(textKind)
    },

    /**
     * Whether any text kind (that is enabled in the feature)
     * is actually configured with prompts in the database.
     */
    isAnyTextKindConfigured () {
      return this.availableTextKindsForCompany.length !== 0
    }
  }
}
</script>
