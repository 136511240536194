<template>
  <div>
    <BaseHeader
      :title="name"
      :style-variant="17"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          Hier finden Sie Statistik, URL und Einbindungscode.
          Plus jede Menge Werbemittel.<br>
          Zusätzlich können Sie sich hier komplett automatisch individuelle Texte erstellen lassen.
        </p>
      </template>
      <template v-slot:content>
        <v-breadcrumbs :items="breadcrumbItems" />
      </template>
    </BaseHeader>

    <v-row
      v-if="landingpage"
      class="mb-2"
    >
      <v-col
        cols="12"
        lg="4"
        md="6"
        class="py-2 pr-3 pr-md-2"
      >
        <v-card
          outlined
          class="h-full"
        >
          <v-card-title>
            <h3 class="overline grey--text text--darken-1">
              Statistik
            </h3>
          </v-card-title>
          <v-card-text
            v-if="hasFilter(landingpageType)"
            class="px-6 primary--text"
            style="cursor:pointer"
            @click="handleClick(landingpageType)"
          >
            <span class="text-h2">
              {{ conversionCount }}
            </span>
            <span> x {{ conversionLabel(landingpageType) }}</span>
          </v-card-text>
          <v-card-text
            v-else
            class="px-6"
          >
            <span class="text-h2">
              {{ conversionCount }}
            </span>
            <span> x {{ conversionLabel(landingpageType) }}</span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="5"
        md="6"
        class="py-2"
      >
        <v-card
          outlined
          class="h-full"
        >
          <v-card-title>
            <h3 class="overline grey--text text--darken-1">
              URLs
            </h3>
          </v-card-title>
          <v-card-text class="px-6 text-body-1">
            <div class="d-flex">
              <span
                class="mr-1 grey--text text--darken-1 text-no-wrap"
              >Link: </span>
              <a
                :href="originalUrl"
                :title="prettyOriginalUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="d-inline-block text-truncate"
              >
                {{ prettyOriginalUrl }}
              </a>
            </div>

            <div class="d-flex">
              <span class="mr-1 grey--text text--darken-1 text-no-wrap">Custom-URL: </span>
              <a
                v-if="customUrl"
                :href="customUrl"
                :title="customUrl"
                target="_blank"
                rel="noopener noreferrer"
                class="d-inline-block text-truncate"
              >
                {{ prettyCustomUrl }}
              </a>
              <div
                v-else
                class="d-flex"
              >
                <span class="mr-2 grey--text">Noch nicht hinterlegt</span>
                <information-button
                  title="Custom URL anlegen"
                  content="Eine benutzerdefinierte URL hilft Ihnen, Ihre Webseite noch individueller zu präsentieren."
                  :link="helpLinks.customUrl"
                  link-text="Zur Anleitung"
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="3"
        class="py-2 pl-3 pl-lg-2"
      >
        <v-card
          outlined
          class="h-full"
        >
          <v-card-title>
            <h3 class="overline grey--text text--darken-1">
              Einbindung
            </h3>
          </v-card-title>
          <v-card-text class="px-6">
            <p>Jetzt {{ readablePrefix }} {{ name }} einbinden</p>
            <SnippetModal
              :landingpage-slug="slug"
              :readable-prefix="readablePrefix"
              :readable-landingpage="name"
              :company-slug="companySlug"
              :company-site-url="companySiteUrl"
              :landingpage-type="landingpageType"
              small
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <TextAi
      v-if="landingpage"
      slug-type="LANDINGPAGE"
      :slug="landingpage.slug"
    />

    <v-card
      v-if="landingpage && hasMaterialsToShow && !isLight"
      outlined
    >
      <v-card-title>
        <h3 class="overline grey--text text--darken-1">
          Materialien ({{ materialCount }})
        </h3>
      </v-card-title>
      <v-card-subtitle>
        Sie können die vorhanden Materialien verwenden, um diese Landingpage aktiv zu bewerben.
      </v-card-subtitle>
      <v-card-text class="px-4">
        <div class="pb-2">
          <template v-if="downloads.length > 0">
            <h3 class="overline">
              Download-Dokumente
            </h3>
            <v-row>
              <v-col
                v-for="{previewFile} in coverFiles"
                :key="previewFile.url"
                :cols="12"
                :sm="4"
                :md="3"
              >
                <MaterialCard
                  name="Cover"
                  :url="setQueryParams(previewFile.cdnUrl, { attachment: '' })"
                  :thumbnail-url="previewFile.cdnUrl"
                />
              </v-col>
              <template
                v-for="material in downloads"
              >
                <v-col
                  v-for="{file, previewFile} in material.files"
                  :key="file.url"
                  :cols="12"
                  :sm="4"
                  :md="3"
                >
                  <MaterialCard
                    :name="material.displayName"
                    :url="file.cdnUrl"
                    :thumbnail-url="previewFile.cdnUrl"
                  />
                </v-col>
              </template>
            </v-row>
          </template>

          <template v-if="basicAds.length > 0">
            <h3 class="mt-4 overline">
              Grundwerbemittel
            </h3>

            <v-row>
              <template
                v-for="material in basicAds"
              >
                <v-col
                  v-for="{file, previewFile} in material.files"
                  :key="file.url"
                  :cols="12"
                  :sm="4"
                  :md="3"
                >
                  <MaterialCard
                    :name="material.displayName"
                    :url="file.cdnUrl"
                    :thumbnail-url="previewFile.cdnUrl"
                  />
                </v-col>
              </template>
            </v-row>
          </template>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="landingpage && !hasMaterialsToShow && !isLight"
      outlined
    >
      <no-content-screen
        title="Noch keine Materialien vorhanden"
        :sub-title="noContentSubtitle"
      />
    </v-card>

    <v-card
      v-if="landingpage && isLight"
      outlined
    >
      <v-card-title>
        <h3 class="overline grey--text text--darken-1">
          Materialien ({{ lightMaterials[landingpage.slug].length }})
        </h3>
      </v-card-title>
      <v-card-subtitle>
        Sie können die vorhanden Materialien verwenden, um diese Landingpage aktiv zu bewerben.
      </v-card-subtitle>
      <v-card-text class="px-4">
        <div class="pb-2">
          <template v-if="lightMaterials[landingpage.slug].length > 0">
            <h3 class="mt-4 overline">
              Grundwerbemittel
            </h3>

            <v-row>
              <template
                v-for="material in lightMaterials[landingpage.slug]"
              >
                <v-col
                  v-for="{file, previewFile} in material.files"
                  :key="file.url"
                  :cols="12"
                  :sm="4"
                  :md="3"
                >
                  <MaterialCard
                    :name="material.displayName"
                    :url="file.cdnUrl"
                    :thumbnail-url="previewFile.cdnUrl"
                  />
                </v-col>
              </template>
            </v-row>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TextAi from '@/modules/textAi'
import SnippetModal from '../landingpages/snippet/index.vue'
import MaterialCard from '../landingpages/MaterialCard'
import LANDINGPAGE from './Landingpage.gql'
import COMPANY_BASE from './CompanyBase.gql'
import { TOOLS } from '@/lib/tools'
import BaseHeader from '@/components/BaseHeader.vue'
import NoContentScreen from '@/components/NoContentScreen.vue'
import helpLinks from '@/lib/helpLinks'
import url from '@/mixins/url'
import InformationButton from '@/components/InformationButton.vue'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'
import HeaderDesign from './HeaderDesign.vue'
import { isLightUser } from '@/lib/productTypes'
import lightMaterials from '@/lib/lightMaterials' // temporary solution for light-product, see comment in lightMaterials.js

const filterOnlyFiles = material => material.hasFile

const ARTICLE = {
  VALUATION: 'die',
  RETURN_CALL: 'die',
  LIFE_ANNUITY: 'den',
  PARTIAL_SALE: 'den',
  PLOT_VALUATION: 'die',
  PROPERTY_TRACKER: 'den',
  QUIZZARD: 'die',
  GUIDE: 'den Ratgeber',
  CHECKLIST: 'die Checkliste'
}

export default {
  components: {
    SnippetModal,
    MaterialCard,
    BaseHeader,
    NoContentScreen,
    InformationButton,
    HeaderDesign,
    TextAi
  },

  mixins: [url],

  data () {
    return {
      helpLinks,
      lightMaterials
    }
  },

  apollo: {
    landingpage: {
      query: LANDINGPAGE,
      variables () {
        return {
          input: {
            landingpageId: this.$route.params.id
          }
        }
      }
    },
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },

  computed: {
    noContentSubtitle () { return this.isLight ? 'Mit Materialien noch erfolgreicher unterwegs' : 'Diese werden in Kürze für Sie bereitgestellt' },
    name () { return this.landingpage?.name || 'Landingpage' },
    slug () { return this.landingpage?.slug || '' },
    conversionCount () { return this.landingpage?.conversionCount || 0 },
    originalUrl () { return this.landingpage?.originalUrl || '' },
    customUrl () { return this.landingpage?.customUrl || '' },
    landingpageType () { return this.landingpage?.type || 'GUIDE' },
    companySlug () { return this.company.slug },
    companySiteUrl () { return this.company?.site.url },
    materials () { return this.landingpage?.materials || [] },
    materialCount () {
      let count = this.coverFiles.length
      this.basicAds.forEach(basicAd => { count += basicAd.files.length })
      this.downloads.forEach(download => { count += download.files.length })
      return count
    },
    breadcrumbItems () {
      const type = this.landingpageType === 'GUIDE' ? 'guides' : this.landingpageType === 'CHECKLIST' ? 'checklists' : TOOLS.find(tool => tool.type === this.landingpageType) ? 'tools' : 'campaigns'
      return [
        { text: 'Landingpages', to: { path: `/landingpages#${type}` }, disabled: false, exact: true },
        { text: 'Details' }
      ]
    },
    coverFiles () {
      const material = this.materials.find(material => (
        material.type === 'DOWNLOAD' &&
        material.purpose === 'SCREEN'
      ))
      if (material && material.files) {
        return material.files
      }
      return []
    },

    downloads () {
      return this.materials
        .filter(filterOnlyFiles)
        .filter(material => material.type === 'DOWNLOAD')
    },

    basicAds () {
      return this.materials
        .filter(filterOnlyFiles)
        .filter(material => material.type === 'BASIC_AD')
    },

    hasMaterialsToShow () {
      return this.downloads.length > 0 || this.basicAds.length > 0
    },

    hasMaterials () {
      return this.materials?.length > 0
    },

    prettyOriginalUrl () {
      return this.originalUrl.replace(/^(https?:\/\/)/, '')
    },

    prettyCustomUrl () {
      if (!this.customUrl) {
        return ''
      }
      return this.customUrl.replace(/^(https?:\/\/)?/, '')
    },

    readablePrefix () {
      return ARTICLE[this.landingpageType]
    },

    isLight () {
      return isLightUser(this.$auth.user)
    }

  },
  methods: {
    hasFilter (type) {
      return TOOLS.find(tool => tool.type === type)
    },
    conversionLabel (type) {
      return TOOLS.find(tool => tool.type === type)?.action || 'heruntergeladen'
    },
    handleClick (type) {
      resetAllFilters()

      const tool = TOOLS.find(tool => tool.type === type)
      persistFilter(tool.key, true)

      this.$router.push('/leads?filter')
    }
  }
}
</script>
