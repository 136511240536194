<template>
  <v-container class="layout-container">
    <Landingpage />
  </v-container>
</template>

<script>
import Landingpage from '@/modules/landingpage'

export default {
  components: {
    Landingpage
  }
}
</script>
