<template>
  <div>
    <div
      v-if="hasState('generating')"
      class="d-flex flex-column align-center pa-4"
    >
      <GenericAnimation
        :animation-data="animationData"
        loop
        autoplay
      />
      <div class="pt-4">
        <p>Wir generieren nun Ihren Text …</p>
        <p>
          Das dauert normalerweise nur wenige Sekunden, kann aber in manchen Fällen bis zu 2 Minuten dauern.
          Aber immer noch schneller, als wenn ein Mensch ihn schreiben würde, oder?
        </p>
      </div>
    </div>

    <div
      v-else
    >
      <h5 class="text-h6">
        Ihr generierter Text
      </h5>

      <p class="text-subtitle-1">
        Wenn Ihnen der Text gefällt, kopieren Sie ihn einfach.
        Sie können auch direkt hier Änderungen vornehmen und den Text dann kopieren.
      </p>

      <v-alert
        v-if="!isNewText"
        type="info"
        icon="mdi-lightbulb-on-20"
        class="mt-4"
        text
      >
        Sie haben für dieses Thema bereits einen Text generiert. Die Ergebnisse haben wir für Sie gespeichert.
        Sie können dennoch jederzeit neue Varianten generieren.
      </v-alert>

      <div class="d-flex align-center">
        <v-btn
          icon
          @click="selectPreviousChoice()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        Variante {{ selectedChoiceIndex + 1 }} / {{ nChoices }}
        <v-btn
          icon
          @click="selectNextChoice()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-textarea
        v-model="selectedChoiceText"
        outlined
        rows="8"
      />

      <p class="text-subtitle-1">
        Noch nicht zufrieden?
        <v-btn
          color="primary"
          text
          @click="generateText"
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          Neu generieren
        </v-btn>
      </p>
    </div>
  </div>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'
import GET_TOPIC_TEMPLATES from './queries/getTopicTemplates.gql'
import GET_LATEST_GENERATED_TEXT from './queries/getLatestGeneratedText.gql'
import GENERATE_TOPIC_TEXT from './queries/generateTopicText.gql'
import AnimationData from '../../../public/animations/paper.json'
import GenericAnimation from '@/components/GenericAnimation.vue'

export default {
  components: {
    GenericAnimation
  },
  props: {
    slugType: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    textKind: {
      type: String,
      required: true
    }
    // parts: [{
    //   key: 'title',
    //   name: 'Titel',
    //   singleLine: true // if false = Textarea
    // }]
  },

  data () {
    return {
      /** idle, generating, generated */
      state: 'idle',
      /**
       * Whether the text or choices displayed are just been generated (true)
       * or are being loaded from the database (false).
       */
      isNewText: false,
      selectedChoiceIndex: 0,
      selectedChoiceText: '',
      animationData: AnimationData
    }
  },

  apollo: {
    topicTemplates: {
      query: GET_TOPIC_TEMPLATES,
      variables () {
        return {
          input: {
            slugType: this.slugType,
            slug: this.slug,
            textKind: this.textKind
          }
        }
      }
    },

    generatedText: {
      query: GET_LATEST_GENERATED_TEXT,
      variables () {
        return {
          input: {
            templateId: this.topicTemplates[0].id
          }
        }
      },
      skip () {
        return !this.topicTemplates?.[0]
      },
      result ({ data, loading }) {
        if (!loading) {
          if (data.generatedText) {
            this.setCurrentChoice(0)
          } else {
            this.generateText()
          }
        }
      }
    }
  },

  computed: {
    nChoices () {
      return this.generatedText?.choices.length ?? 1
    }
  },

  methods: {
    hasState (state) {
      return this.state === state
    },

    async generateText () {
      this.state = 'generating'

      try {
        const { data } = await this.$apollo.mutate({
          mutation: GENERATE_TOPIC_TEXT,
          variables: {
            input: {
              topicTemplateId: this.topicTemplates[0].id
            }
          }
        })

        this.generatedText = data.generatedText
        this.isNewText = true
        this.setCurrentChoice(0)
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'error',
          text: 'Fehler beim Generieren des Textes. Versuchen Sie es später erneut.'
        })
      }

      this.state = 'generated'
    },

    selectNextChoice () {
      const choiceIndex = Math.min(this.selectedChoiceIndex + 1, this.nChoices - 1)
      this.setCurrentChoice(choiceIndex)
    },

    selectPreviousChoice () {
      const choiceIndex = Math.max(this.selectedChoiceIndex - 1, 0)
      this.setCurrentChoice(choiceIndex)
    },

    setCurrentChoice (choiceIndex) {
      this.selectedChoiceIndex = choiceIndex
      this.selectedChoiceText = this.generatedText.choices[this.selectedChoiceIndex]
    }
  }
}
</script>
