<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        outlined
        large
        v-on="on"
      >
        <v-icon
          left
          v-text="buttonIcon"
        />
        {{ buttonLabel }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title v-text="title" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pb-0 mt-2">
        <Generator
          v-if="isConfigured"
          :may-generate="isDialogOpen"
          :text-kind="textKind"
          :slug-type="slugType"
          :slug="slug"
        />

        <v-alert
          v-else
          type="info"
          icon="mdi-lightbulb-on-20"
          class="mt-4"
          text
        >
          <strong>Die KI muss noch lernen, aktuell kann sie keinen {{ buttonLabel }} für das gewählte Thema erstellen.</strong><br>
          Schauen Sie bei Gelegenheit erneut vorbei.
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="close"
        >
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Generator from './Generator.vue'

export default {
  components: {
    Generator
  },

  props: {
    title: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true
    },
    buttonIcon: {
      type: String,
      required: true
    },
    textKind: {
      type: String,
      required: true
    },
    slugType: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    isConfigured: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDialogOpen: false
    }
  },

  methods: {
    close () {
      this.isDialogOpen = false
    }
  }
}
</script>
